import React from "react";
import img from "../../images/coverphoto.png";
import "./tophead.css";

function TopHead() {
	return (
		<div className="pt-5" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container blogpost">
				<div className="px-magic text-center text-md-start">
					<h1>How Brands are Shifting from Vanity Metrics to True Revenue Metrics<hr></hr></h1> 
					{/*<h5>Anticipated Challenges & Opportunities for Brands in the near Future</h5>*/}
					<h4>18 October 2024 | By Venkat Terugu</h4>
				</div>
			</div>
			<img className="cover-img" src={img} alt="cover_pic" />
		</div>
	);
}
export default TopHead;