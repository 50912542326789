import React from 'react'
import Card from 'react-bootstrap/Card';
import img1 from '../../images/home8.webp';
import img2 from '../../images/home1.webp';
import img3 from '../../images/home5.webp';
import img4 from '../../images/home3.webp';
import './potential.css';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function potential() {
    return (
        <div className="potential">
      <div className="container potential-content">
          <h2>Unlock your full potential with <span>Brandwise</span></h2>

          {/* Carousel for mobile view only */}
          <div className="d-block d-md-none">
            <Carousel interval={2000} controls={false} indicators={false} pause={false}> {/* Automatic sliding */}
              <Carousel.Item>
                <Card className="custom-card" >
                  <Card.Img variant="top" src={img2} className='top-card-img' alt="Customer enjoying seamless omnichannel experiences, empowering brands to build strong 
                  connections and drive loyalty across platforms" />
                  <Card.Body>
                    <Card.Title>DIGITAL PRODUCT MASTERY</Card.Title>
                    <Card.Text>
                      Transform your physical products into smart, connected assets enabling authentication, traceability, and interactive consumer experiences.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>

              <Carousel.Item>
                <Card className="custom-card">
                  <Card.Img variant="top" src={img1} className='top-card-img' alt="Phone scanning QR code on packaging which enables interactive consumer 
                  experiences, authenticity and traceability "/>
                  <Card.Body>
                    <Card.Title>OMNICHANNEL ENGAGEMENT EXCELLENCE</Card.Title>
                    <Card.Text>
                      Create meaningful connections across all channels that resonate with your audience and drive brand loyalty.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>

              <Carousel.Item>
                <Card className="custom-card">
                  <Card.Img variant="top" src={img3} className='top-card-img' alt="Business woman analyzing first party data gathered from Brandwise to create informed 
                  marketing strategies and development"/>
                  <Card.Body>
                    <Card.Title>DATA DRIVEN DECISION</Card.Title>
                    <Card.Text>
                      Harness the power of first-party data with actionable insights for informed marketing strategies and product development.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>

              <Carousel.Item>
                <Card className="custom-card">
                  <Card.Img variant="top" src={img4} className='top-card-img' alt="Maximize Marketing ROI with precision insights and data 
                  that help fine-tune your growth strategies"/>
                  <Card.Body>
                    <Card.Title>MARKETING ROI MAXIMIZATION</Card.Title>
                    <Card.Text>
                      Maximize the impact of your marketing efforts and fine-tune your strategies for optimal returns on your marketing investments.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>

          {/* Grid layout for larger screens only */}
          <div className="d-none d-md-flex row">
            <div className="col-md-3 quard">
              <Card className="custom-card">
                <Card.Img variant="top" src={img2} className='top-card-img' alt="Customer enjoying seamless omnichannel experiences, empowering brands to build 
                strong connections and drive loyalty across platforms"/>
                <Card.Body>
                  <Card.Title>DIGITAL PRODUCT <br />MASTERY</Card.Title>
                  <Card.Text>
                    Transform your physical products into smart, connected assets enabling authentication, traceability, and interactive consumer experiences.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 quard">
              <Card className="custom-card">
                <Card.Img variant="top" src={img1} className='top-card-img' alt=" Phone scanning QR code on packaging which enables interactive consumer 
                experiences, authenticity and traceability "/>
                <Card.Body>
                  <Card.Title>OMNICHANNEL <br /> ENGAGEMENT EXCELLENCE</Card.Title>
                  <Card.Text>
                    Create meaningful connections across all channels that resonate with your audience and drive brand loyalty.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 quard">
              <Card className="custom-card">
                <Card.Img variant="top" src={img3} className='top-card-img' alt="Business woman analyzing first party data gathered from Brandwise to create 
                informed marketing strategies and development"/>
                <Card.Body>
                  <Card.Title>DATA DRIVEN<br /> DECISION</Card.Title>
                  <Card.Text>
                    Harness the power of first-party data with actionable insights for informed marketing strategies and product development.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 quard">
              <Card className="custom-card">
                <Card.Img variant="top" src={img4} className='top-card-img' alt="Maximize Marketing ROI with precision insights and 
                data that help fine-tune your growth strategies"/>
                <Card.Body>
                  <Card.Title>MARKETING ROI MAXIMIZATION</Card.Title>
                  <Card.Text>
                    Maximize the impact of your marketing efforts and fine-tune your strategies for optimal returns on your marketing investments.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

        </div><br /><br /><br />
      
    </div>
    )
}
