import React from 'react'
import Card from 'react-bootstrap/Card';
import img1 from '../../images/prod1.webp';
import img2 from '../../images/prod2.webp';
import img3 from '../../images/prod3.webp';
import img4 from '../../images/prod4.webp';
import img5 from '../../images/digi1.webp';
import img6 from '../../images/digi2.webp';
import img7 from '../../images/digi3.webp';
import img8 from '../../images/qreach4.png';
import img9 from '../../images/personal1.webp';
import img10 from '../../images/personal2.webp';
import img11 from '../../images/personal3.webp';
import img12 from '../../images/personal4.webp';
import img13 from '../../images/mean1.webp';
import img14 from '../../images/mean2.webp';
import img15 from '../../images/mean3.webp';
import img16 from '../../images/mean4.webp';
import './prod-digi.css';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function prodDigi() {
    return (
        <div className="product">
            <div className="container prod-digi-content">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Product Digitization & Robust Authentication</span>
                    </h2>
                    <h2>
                        <span>QSeal</span>
                    </h2>
                </div>

                {/* Grid layout for larger screens only */}
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img1} alt="Counterfeit fashion products with a 'Fake' stamp, 
                            highlighting the importance of authenticity in retail and brand protection"/>
                            <div class="card-header">
                                Counterfeit Protection
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Protect Customers form Fake
                                    Products that Damages Image
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img2} alt="Icon of a handshake and shield representing trust, security, and partnership 
                            in business relationships, emphasizing faith" />
                            <div class="card-header">
                                Win Customer’s Trust
                            </div>
                            <Card.Body className='product-body'>
                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Create Reliability and Faith
                                    within your Industry
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img3} alt="Real time data and insights collected by Brandwise for the admin to 
                            understand customer behavior towards their products"/>
                            <div class="card-header">
                                Interaction Data in Real Time
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Understand Customer’s
                                    Behavior towards your
                                    Products
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img4} alt="Effortless visualization of your supply chain to track each batch’s journey in 
                            transit. Achieved through digitization."/>
                            <div class="card-header">
                                Supply Chain Traceability
                            </div>
                            <Card.Body className='product-body'>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Digitize Products to Monitor
                                    each batch’s journey in transit
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Immersive Customer Engagement & Digital
                            Experiences</span>
                    </h2>
                    <h2>
                        <span>QReach</span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">

                            <Card.Img variant="top" src={img5} alt="Create personalized, customized and relevant marketing campaigns to deliver memorable 
                            customer experiences that boost engagement."/>
                            <div class="card-header">
                                16+ Campaign Options
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Create relevant, personalized
                                    & customized experiences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img6} alt="Customer data and profiling that helps to engage and understand consumer 
                            behavior for improved campaign performance"/>
                            <div class="card-header">
                                Customer Insights & Analytics
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Understand, Interact & Engage
                                    with Every Customer
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img7} alt="Access, build and manage real time action alerts from Brandwise through a 
                            single API that helps you take swift action."/>
                            <div class="card-header">
                                Real Time Action Alerts
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Build, manage & send
                                    transaction alerts through a
                                    single API
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img8} alt="Customer engaging with omnichannel marketing across digital and physical platforms for 
                            enhanced interaction and seamless brand experience"/>
                            <div class="card-header">
                                Omnichannel Communication
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Personalize & Connect on
                                    multiple impactful channels
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />

                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Personalized Story Telling & Loyalty Programs</span>
                    </h2>

                </div>
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img9} alt="Delivering relevant customer experiences at every stage with 
                            insightful and accurate customer journey mapping"/>
                            <div class="card-header">
                                Customer Journey Mapping
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Deliver relevant customer
                                    experiences at every stage
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img10} alt="Customers enjoying personalized experiences providing good 
                            reviews which in turn translates to brand loyalty" />
                            <div class="card-header">
                                Long Term Loyalty
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Reduce Churn by sending
                                    tailored offers, coupons, gift
                                    cards, etc.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img11} alt="Attract customers with seamless post sale experiences so that your 
                            brand can have satisfied buyers coming back for more" />
                            <div class="card-header">
                                Sustainable Retention
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Relieve Customer from Post
                                    Sale Challenges, Let Brandwise
                                    Take Care of it!
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img12} alt="Increase revenue by expanding wallet share per customer. 
                            Reactivate dormant customers and increase spend."/>
                            <div class="card-header">
                                Expand Wallet Share
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Reactivate dormant customers
                                    and Increase customer spend
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        <span>Meaningful Analytics & Rewarding Insights</span>
                    </h2>

                </div>
                <div className=" row">
                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img13} alt="Customer choosing between two buying options, where Brandwise gathers 
                            reliable data on customer preferences" />
                            <div class="card-header">
                                Accurate Buyer Behavior
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Gather realistic and reliable
                                    data on customer preferences
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img14} alt="Data Dashboard to manage and track multiple campaign 
                            performances at once to determine ROI, Growth and more." />
                            <div class="card-header">
                                Manage Campaign ROI Data
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Mange and track multiple
                                    campaign performances at
                                    once
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img15} alt="Study data that is tailored to meet your specific needs on Brandwise Dashboards to get a 
                            clear overview that matters to you"/>
                            <div class="card-header">
                                Data That Matter To You
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Study the data that is tailored
                                    to your specific needs
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3 quard">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img16} alt="brand executive enjoys a simple and user friendly UI that helps getting 
                            work done effortlessly and efficiently." />
                            <div class="card-header">
                                Effortless & User Friendly
                            </div>
                            <Card.Body>

                                <Card.Text className="main-text" style={{ fontSize: "14px", color: "black" }}>
                                    Simple yet powerful UI so that
                                    you can get the most,
                                    effortlessly
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />
            </div><br /><br /><br />
        </div>
    )
}
